import React, { useCallback } from 'react'
import { FunctionalComponent } from 'preact'
import { Field, useFormState } from 'react-final-form'
import { TextField } from '../../../dashboard/components/TextField'
import { WhiteReactSelectAdapter } from '../../../../controls/inputs/generalinputs/whiteReactSelectAdapter'
import { NumberField } from '../../../dashboard/components/NumberField'

type Props = any
export type Option = {
    value: string
    label: string
}
export const propertyTypeList: Array<Option> = [
    { value: 'Conventional Multifamily', label: 'Conventional Multifamily' },
    { value: 'Limited Equity Cooperative', label: 'Limited Equity Cooperative' },
    { value: 'Manufactured Housing Community', label: 'Manufactured Housing Community' },
    { value: 'Market-Cooperative', label: 'Market-Cooperative' },
    { value: 'Military', label: 'Military' },
    { value: 'Seniors', label: 'Seniors' },
    { value: 'Student - Dedicated', label: 'Student - Dedicated' },
    { value: 'Student - Non-Dedicated', label: 'Student - Non-Dedicated' },
    { value: 'Affordable', label: 'Affordable' },
    { value: 'ARM / SARM', label: 'ARM / SARM' },
]
export const buildingTypeeList: Array<Option> = [
    { value: 'Garden', label: 'Garden' },
    { value: 'High Rise', label: 'High Rise' },
    { value: 'Mid Rise', label: 'Mid Rise' },
    { value: 'Single Family Rental', label: 'Single Family Rental' },
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Other', label: 'Other' },
]

export const statesList: Array<Option> = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
]

export const PropertyAndBorrower: FunctionalComponent<Props> = (props: Props) => {
    const formState = useFormState()
    const formValues = formState.values
    if (!formValues?.propertyAndBorrower?.principals || formValues?.propertyAndBorrower?.principals.length == 0) {
        formValues.propertyAndBorrower.principals = [{ name: '', guarantor: false }]
    }

    const addPrincipal = useCallback(() => {
        const principals = props.localContext.form.getState().values.propertyAndBorrower.principals
        props.localContext.form.change(`propertyAndBorrower.principals.${principals.length}`, {
            name: '',
            guarantor: false,
        })
    }, [])

    const deletePrincipal = useCallback((e) => {
        props.localContext.form.mutators.deletePrincipal(e)
    }, [])

    return (
        <div className={'lender-publish-wrapper'}>
            <div className="lender-publish-page-title mainTitle mainTitle1">
                Let’s get to know the property and the borrower better
            </div>
            <div className="lender-publish-page-title mainTitle mainTitle2">Property Details</div>
            <div className={'row'}>
                <div className={'label'}>Property Name</div>
                <TextField name={`propertyAndBorrower.propertyName`} className={'fvalue'} />
            </div>
            <div className={'row row2'}>
                <div className={'row2child'}>
                    <div className={'label'}>Year Built</div>
                    <NumberField
                        numberFormat={'number'}
                        defaultValue={0}
                        name={`propertyAndBorrower.yearBuilt`}
                        cssClassName={'fvalue'}
                    />
                </div>
                <div className={'row2child'}>
                    <div className={'label'}>Last Renovated</div>
                    <NumberField
                        numberFormat={'number'}
                        defaultValue={0}
                        name={`propertyAndBorrower.yearRenovated`}
                        cssClassName={'fvalue'}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'label'}>Number Of Units</div>
                <NumberField
                    numberFormat={'number'}
                    defaultValue={0}
                    name={`propertyAndBorrower.numberOfUnits`}
                    cssClassName={'fvalue'}
                />
            </div>
            <div className={'row'}>
                <div className={'label'}>Address</div>
                <TextField name={`propertyAndBorrower.address`} className={'fvalue'} readOnly={true} />
            </div>
            <div className={'row row2'}>
                <div className={'row2child66'}>
                    <div className={'label'}>City</div>
                    <TextField name={`propertyAndBorrower.city`} className={'fvalue'} readOnly={true} />
                </div>
                <div className={'row2child33'}>
                    <div className={'label'}>Zip</div>
                    <TextField name={`propertyAndBorrower.zip`} className={'fvalue'} readOnly={true} />
                </div>
            </div>
            <div className={'row'}>
                <div className={'label'}>State</div>
                <Field
                    component={WhiteReactSelectAdapter}
                    name="propertyAndBorrower.state"
                    defaultValue={statesList[0].value}
                    options={statesList}
                    isDisabled={false}
                />
            </div>
            <div className={'row'}>
                <div className={'label'}>County</div>
                <TextField name={`propertyAndBorrower.county`} className={'fvalue'} />
            </div>
            <div className={'row'}>
                <div className={'label'}>MSA</div>
                <TextField name={`propertyAndBorrower.msa`} className={'fvalue'} />
            </div>
            <div className={'row'}>
                <div className={'label'}>Census Tract</div>
                <TextField name={`propertyAndBorrower.censusTract`} className={'fvalue'} />
            </div>
            <div className={'row'}>
                <div className={'label'}>Property Type</div>
                <Field
                    component={WhiteReactSelectAdapter}
                    name="propertyAndBorrower.propertyType"
                    defaultValue={propertyTypeList[0].value}
                    options={propertyTypeList}
                    isDisabled={false}
                />
            </div>
            <div className={'row'}>
                <div className={'label'}>Building Type</div>
                <Field
                    component={WhiteReactSelectAdapter}
                    name="propertyAndBorrower.buildingType"
                    defaultValue={buildingTypeeList[0].value}
                    options={buildingTypeeList}
                    isDisabled={false}
                />
            </div>
            <div className={'row'}>
                <Field name={'propertyAndBorrower.MAH'} component={'input'} type="checkbox" className={'GICheckbox'} />
                <div className={'label labelCheckbox'}>MAH (Gateway)</div>
            </div>

            <div className={'row'}>
                <Field
                    name={'propertyAndBorrower.lowRatioAffordable'}
                    component={'input'}
                    type="checkbox"
                    className={'GICheckbox'}
                />
                <div className={'label labelCheckbox'}>Low Ratio Affordable</div>
            </div>

            <div className={'row'}></div>
            <div className="lender-publish-page-title mainTitle mainTitle2" style={{ marginBottom: '25px}' }}>
                Borrower Details
            </div>
            <div className={'row'}>
                <div className={'label'}>Borrower Name</div>
                <TextField name={`propertyAndBorrower.borrowerName`} className={'fvalue'} />
            </div>

            {formValues?.propertyAndBorrower?.principals && formValues?.propertyAndBorrower?.principals.length > 0 && (
                <>
                    {formValues?.propertyAndBorrower?.principals.map((itm, id) => {
                        return (
                            <React.Fragment key={'principal' + id}>
                                <div className={'principalRow'}>
                                    <div className={'principalMain'}>
                                        <div className={'principalLabel'}>Key Principal</div>
                                        <TextField
                                            name={`propertyAndBorrower.principals.${id}.name`}
                                            className={'principalName'}
                                        />
                                    </div>
                                    <div className={'principalGurantor'}>
                                        <Field
                                            name={`propertyAndBorrower.principals.${id}.guarantor`}
                                            component={'input'}
                                            type="checkbox"
                                            className={'principalCheckbox'}
                                        />
                                        <div className={'label labelCheckbox'}>Guarantor</div>
                                    </div>
                                    {formValues?.propertyAndBorrower?.principals.length > 1 && (
                                        <div className="delete">
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    deletePrincipal('' + id)
                                                }}
                                            >
                                                <div className="delete-icon">
                                                    <img src="/_img/lenderDashboard/trash.svg" alt="trash" />
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        )
                    })}
                </>
            )}

            <div className={'addPrincipal'} onClick={addPrincipal}>
                + Add Key Principal
            </div>

            <div className={'row'} style={{ marginBottom: '100px' }}></div>

            {/*language=scss*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .principalRow {
                        width: 1000px !important;
                        margin-bottom: 20px;
                        margin-left: 0px;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .principalLabel {
                        width: 380px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400 !important;
                        font-size: 14px !important;
                        line-height: 18px;
                        color: #687893 !important;
                        margin-bottom: 10px;
                    }
                    .principalGurantor {
                        top: 35px;
                        position: relative;
                        margin-left: 30px;
                    }
                    .principalMain {
                        width: 380px;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    :global(.principalName) {
                        background-color: white !important;
                        width: 380px !important;
                        height: 38px !important;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 12px;
                        gap: 8px;
                        isolation: isolate;
                        border: 1px solid #97a2b4 !important;
                        border-radius: 4px !important;
                    }
                    .principalCheckbox {
                        position: relative;
                        top: 3px;
                    }
                    .delete {
                        width: 36px;
                        height: 36px;
                        position: relative;
                        top: 25px;
                        right: 70px;
                    }
                    .delete-icon {
                        width: 16px;
                        height: 16px;

                        color: #97a2b4;
                        position: relative;
                        left: 5px;
                        top: 5px;
                        color: #97a2b4;
                    }

                    .addPrincipal {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        /* identical to box height */
                        cursor: pointer;
                        color: #4486ff;
                    }
                `}
            </style>
        </div>
    )
}
