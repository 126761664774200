export const ROUTE = Object.freeze({
    ACCOUNT: '/v1/account',
    DASHBOARD: '/v1/dashboard',
    STATISTICS: '/v1/statistics',
    LENDER_DASHBOARD: '/v1/dashboard',
    PROJECTS: '/v1/projects',
    LENDER_PROJECTS: '/v1/lender-projects',
    QUICK_ANALYSIS: '/v1/quick-analysis',
    QUICK_ANALYSIS_BORROWER: '/v1/quick-analysis-borrower',
    FULL_PROJECT: '/v1/fullproject',
    OM_EDITOR: '/v1/project',
    NEW_PROJECT: '/v1/new-project',
    NEW_LENDER_PROJECT: '/v1/lender-new-project',
    PROJECT: '/v1/project',
    PROJECT_WIZARD: '/v1/project-wizard',
    LOGIN: '/v1/login',
    DASHBOARD_SETTINGS: '/v1/dashboard-settings',
    PROJECT_SETTINGS: '/v1/project-settings',
    ASSUMPTIONS: '/v1/operating-assumptions',
    NARRATIVE_EDITOR: '/dealnarrative/editor',
    NARRATIVE_REPORT: '/dealnarrative/report',
    NARRATIVE_OM: '/dealnarrative/om',
    OM: '/expose/',
    MINI_SITE: {
        DEAL_HIGHLIGHTS: 'highlights',
        SUMMARY: 'summary',
        PROPERTY: 'property',
        MARKET_OVERVIEW: 'neighborhood',
        MIX_USE_RETAIL: 'mixuseretail',
        SPONSORS: 'sponsors',
        PRO_FORMA: 'pro-forma',
        DOCUMENTS: 'files',
        CONTACT_US: 'contact',
        RENTCOMPS: 'rent-comparables',
        SALECOMPS: 'property#Comparables',
    },
})
