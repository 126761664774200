import React from 'react'
import { theme } from '../../theme'
export function Spinner() {
    return (
        <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
            {/*language=CSS*/}
            <style jsx>{`
                .lds-ring {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                }
                .lds-ring div {
                    box-sizing: border-box;
                    display: block;
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    margin: 4px;
                    border: 4px solid ${theme.colors.blue};
                    border-radius: 50%;
                    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    border-top-color: ${theme.colors.blue};
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }
                .lds-ring div:nth-child(1) {
                    animation-delay: -0.45s;
                }
                .lds-ring div:nth-child(2) {
                    animation-delay: -0.3s;
                }
                .lds-ring div:nth-child(3) {
                    animation-delay: -0.15s;
                }
                @keyframes lds-ring {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </div>
    )
}
