import React, { useCallback } from 'react'
import { FieldProps, Field, FieldRenderProps } from 'react-final-form'

type Props = FieldProps<any, any> & {
    className?: string
    label?: string
    selectedValue?: any
    disableUncheck?: boolean
    mode?: 'checkbox' | 'radio'
    initialValue?: any
}
export function BooleanField(props: Props) {
    const mode = props.mode || 'checkbox'
    return (
        <Field
            name={props.name}
            component={BooleanAdapter}
            className={props.className}
            label={props.label}
            disableUncheck={props.disableUncheck}
            selectedValue={props.selectedValue}
            mode={mode}
            allowNull={true}
            initialValue={props.initialValue}
        />
    )
}
export function BooleanAdapter(props: FieldRenderProps<boolean>) {
    const toggle = useCallback(() => {
        if (props.input.value && props.disableUncheck) return
        if (props.mode == 'radio') {
            props.input.onChange(props.selectedValue)
        } else {
            if (props.selectedValue) {
                if (props.input.value) {
                    props.input.onChange(null)
                } else {
                    props.input.onChange(props.selectedValue)
                }
            } else {
                props.input.onChange(!props.input.value)
            }
        }
    }, [props.input.value])
    let checked: boolean
    if (props.mode == 'radio') {
        checked = props.input.value === props.selectedValue
    } else {
        checked = !!props.input.value
    }
    return (
        <>
            <div className={'check-container ' + props.className}>
                <div
                    className={`check `}
                    dangerouslySetInnerHTML={{ __html: checked ? '&check;' : '' }}
                    onClick={toggle}
                />
                {props.label && (
                    <div className={'label'} onClick={toggle}>
                        {props.label}
                    </div>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .check-container {
                    display: flex;
                    flex-direction: row;
                }
                .check {
                    width: 22px;
                    height: 22px;
                    border: 1px solid $border;
                    background-color: #fff;
                    font-size: 22px;
                    font-weight: bold;
                    color: $blue;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                }
                .label {
                    margin-left: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    color: $default-text-color;
                }
            `}</style>
        </>
    )
}
