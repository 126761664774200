import React, { CSSProperties, useMemo } from 'react'
import 'devextreme/dist/css/dx.light.css'
import SelectBox from 'devextreme-react/select-box'
import { Field, FieldProps } from 'react-final-form'
import { css } from 'styled-jsx/css'
import DataSource from 'devextreme/data/data_source'
type Props = {
    name: string
    readOnly?: boolean
    className?: string
    style?: CSSProperties
    values: any[]
    valueFld?: string
    displayFld?: string
    showClearButton?: boolean
    placeholder?: string
    onChange?: (value: any) => void
    disabled?: boolean
}

export const SelectField = React.memo((props: Props & FieldProps<any, any>) => {
    const displayFld = props.displayFld || 'label'
    const valueFld = props.valueFld || 'value'
    const values = useMemo(() => {
        return props.values.map((v) => {
            return typeof v == 'string' ? { label: v, value: v } : v
        })
    }, [props.values])

    return (
        <Field name={props.name} initialValue={props.initialValue} defaultValue={props.defaultValue}>
            {(fieldProps) => {
                return (
                    <>
                        <span
                            className={`input-container ${props.readOnly ? 'readOnly' : ''} ${props.className || ''}`}
                            style={props.style}
                        >
                            {props.readOnly ? (
                                props.values.reduce((acc, item) => {
                                    if (item[valueFld] == fieldProps.input.value) {
                                        return item[displayFld]
                                    }
                                    return acc
                                }, '')
                            ) : (
                                <SelectBox
                                    defaultValue={props?.values?.[0]?.[valueFld]}
                                    value={fieldProps?.input?.value}
                                    valueExpr={valueFld}
                                    displayExpr={displayFld}
                                    placeholder={props.placeholder || ''}
                                    dataSource={values}
                                    searchEnabled={true}
                                    showClearButton={props.showClearButton}
                                    onValueChanged={(v) => {
                                        fieldProps.input.onChange(v?.value)
                                        if (props.onChange) {
                                            props.onChange(v?.value)
                                        }
                                    }}
                                    name={fieldProps?.input?.name}
                                    className={`${props.innerclassName || ''}`}
                                    disabled={!!props.disabled}
                                />
                            )}
                        </span>
                        {/*language=SCSS*/}
                        <style jsx>{styles}</style>
                    </>
                )
            }}
        </Field>
    )
})

type SelectComponentProps = {
    name?: string
    readOnly?: boolean
    className?: string
    style?: CSSProperties
    values: any[]
    valueFld?: string
    displayFld?: string
    showClearButton?: boolean
    placeholder?: string
    value?: any
    onChange?: (v: any) => void
}
export function SelectComponent(props: SelectComponentProps) {
    const displayFld = props.displayFld || 'label'
    const valueFld = props.valueFld || 'value'
    const values = useMemo(() => {
        return props.values.map((v) => {
            return typeof v == 'string' ? { label: v, value: v } : v
        })
    }, [props.values])
    return (
        <>
            <span className={`input-container ${props.readOnly ? 'readOnly' : ''}`} style={props.style}>
                <SelectBox
                    defaultValue={props?.values?.[0]?.[valueFld]}
                    value={props.value}
                    valueExpr={valueFld}
                    displayExpr={displayFld}
                    placeholder={props.placeholder || ''}
                    dataSource={values}
                    searchEnabled={true}
                    showClearButton={props.showClearButton}
                    onValueChanged={props.onChange}
                    name={props.name}
                />
            </span>
            {/*language=SCSS*/}
            <style jsx>{styles}</style>
        </>
    )
}

type EditableSelectComponentProps = {
    readOnly?: boolean
    name?: string
    className?: string
    style?: CSSProperties
    values: string[]
    showClearButton?: boolean
    placeholder?: string
    value?: any
    onChange?: (v: any) => void
    acceptCustomValue?: boolean
}
export function EditableSelectComponent(props: EditableSelectComponentProps) {
    const valuesDataSource = useMemo(() => {
        return new DataSource({
            store: props.values.map((t, i) => {
                return { id: i, label: t }
            }),

            key: 'id',
        })
    }, [props.values])

    const customItemCreating = async (args) => {
        if (!args.text) {
            args.customItem = null
            return
        }

        const incrementedId = valuesDataSource.items().length + 1
        const newItem = {
            label: args.text,
            id: incrementedId,
        }

        args.customItem = valuesDataSource
            .store()
            .insert(newItem)
            .then(() => valuesDataSource.load())
            .then(() => newItem)
            .catch((error) => {
                throw error
            })
    }
    return (
        <>
            <span className={`input-container ${props.readOnly ? 'readOnly' : ''}`} style={props.style}>
                <SelectBox
                    defaultValue={props?.values?.[0]}
                    value={props.value}
                    valueExpr={'label'}
                    displayExpr={'label'}
                    placeholder={props.placeholder || ''}
                    dataSource={valuesDataSource}
                    searchEnabled={true}
                    showClearButton={props.showClearButton}
                    onValueChanged={props.onChange}
                    name={props.name}
                    acceptCustomValue={props.acceptCustomValue}
                    onCustomItemCreating={customItemCreating}
                />
            </span>
            {/*language=SCSS*/}
            <style jsx>{styles}</style>
        </>
    )
}

/*language=scss*/
const styles = css`
    @import './src/scss/colors.scss';
    .input-container {
        position: relative;
        background-color: white;
        width: 100%;
        &:after {
            width: 100%;
            border-width: 0;
            text-align: right;
            font-weight: 500;
            font-size: 14px;
            color: $default-text-color;
            height: 26px;
            border-top: 1px solid transparent;
            background-color: transparent;
        }
        &:focus-within:after {
            border-top: 1px solid transparent;
        }

        &.readOnly :global(.input:focus) {
            border: inherit;
        }
        :global(.dx-state-focused) {
            border: 1px solid $accent;
        }
        :global(.dx-scrollable-content .dx-state-active) {
            background-color: $accent !important;
        }
    }
    .input-container.readOnly:after {
        border-top: 1px solid transparent;
    }
`
