import React from 'react'
import DateBox from 'devextreme-react/date-box'
type Props = any
import { Field, FieldProps } from 'react-final-form'
export const DateField = React.memo<FieldProps<any, any> & Props>((props: FieldProps<any, any> & Props) => {
    const displayFormat = props.displayFormat || 'shortDate'
    const onOpenedCb = props.onOpenedCb
    const onChangedCb = props.onChangedCb
    return (
        <>
            <Field {...props}>
                {(p) => {
                    let val
                    if (p.input?.value === '') {
                        val = null
                    } else {
                        val = p.input.value
                    }
                    if (val && typeof val === 'string') {
                        if (val.indexOf('-') > -1) {
                            const d = new Date(val)
                            val = d.getTime()
                        } else {
                            val = parseInt(val)
                        }
                    }
                    // console.log('Selected ', val)
                    return (
                        <DateBox
                            className={'date-field'}
                            value={val}
                            type="date"
                            onValueChanged={(e) => {
                                //console.log('out', e.value, typeof e.value)
                                if (displayFormat && e.value != null && typeof e.value === 'number') {
                                    e.value = '' + e.value
                                }
                                p.input.onChange(e.value)
                                if (onChangedCb) onChangedCb(e)
                            }}
                            displayFormat={displayFormat}
                            useMaskBehavior={true}
                            style={props.style || {}}
                            onOpened={() => {
                                if (onOpenedCb) onOpenedCb()
                            }}
                            min={props.minDate || null}
                        />
                    )
                }}
            </Field>
            {/*language=SCSS*/}
            <style jsx global>
                {`
                    @import './src/scss/colors.scss';
                    .date-field {
                        width: 180px;
                        height: 38px;
                        border: 1px solid #97a2b4;
                        border-radius: 4px;
                        background-color: white !important;
                        .dx-dropdowneditor-icon:before {
                            color: $black !important;
                        }
                        .dx-texteditor-input {
                            color: $black !important;
                            font-weight: 500 !important;
                            font-size: 14px !important;
                        }
                        &.dx-dropdowneditor-active {
                            border: 1px solid $blue !important;
                            border-radius: 3px !important;
                        }
                    }
                    .dx-calendar-caption-button {
                        .dx-button-text {
                            color: $blue !important;
                        }
                    }
                    .dx-calendar-navigator-next-view.dx-button .dx-icon-chevronright {
                        color: $blue;
                    }
                `}
            </style>
        </>
    )
})
/*
dx-calendar-caption-button dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-text
 */
