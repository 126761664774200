import React, { useCallback, useState } from 'react'
import { FunctionalComponent } from 'preact'
import { Field, useFormState } from 'react-final-form'
import { TextField } from '../../../dashboard/components/TextField'
import { NumberField } from '../../../dashboard/components/NumberField'
import dollar from './dollar.svg'
import { TextCheckBox } from '../../../../controls/inputs/generalinputs/TextCheckBox'
import { WhiteReactSelectAdapter } from '../../../../controls/inputs/generalinputs/whiteReactSelectAdapter'

type Props = any

type Option = {
    value: string
    label: string
}
export const greenFinancingTypeList: Array<Option> = [
    { value: 'None', label: 'None' },
    { value: 'N/A', label: 'N/A' },
    { value: 'Green Rewards', label: 'Green Rewards' },
    { value: 'Green Preservation Plus', label: 'Green Preservation Plus' },
    { value: 'Green Building Certificate', label: 'Green Building Certificate' },
    { value: ' Other Green Financing', label: ' Other Green Financing' },
]
export const greenBuildingCertificationTypeList: Array<Option> = [
    { value: 'No Green Building Certification', label: 'No Green Building Certification' },
    { value: 'EarthCraft', label: 'EarthCraft' },
    { value: 'ENERGY STAR Certified Homes', label: 'ENERGY STAR Certified Homes' },
    { value: 'ENERGY STAR Existing Multifamily Building', label: 'ENERGY STAR Existing Multifamily Building' },
    {
        value: 'ENERGY STAR Qualified Multifamily High Rise - Performance',
        label: 'ENERGY STAR Qualified Multifamily High Rise - Performance',
    },
    {
        value: 'ENERGY STAR Qualified Multifamily High Rise - Prescriptive',
        label: 'ENERGY STAR Qualified Multifamily High Rise - Prescriptive',
    },
    { value: 'Enterprise Green Communities Criteria', label: 'Enterprise Green Communities Criteria' },
    {
        value: 'Green Globes Multifamily for Existing Buildings',
        label: 'Green Globes Multifamily for Existing Buildings',
    },
    { value: 'Green Globes Multifamily for New Construction', label: 'Green Globes Multifamily for New Construction' },
    {
        value: 'GreenPoint Rated Existing Multifamily Whole Building',
        label: 'GreenPoint Rated Existing Multifamily Whole Building',
    },
    { value: 'GreenPoint Rated New Home Multifamily', label: 'GreenPoint Rated New Home Multifamily' },
    { value: 'ILFI Zero Energy Certification', label: 'ILFI Zero Energy Certification' },
    { value: 'LEED Building Design and Construction', label: 'LEED Building Design and Construction' },
    { value: 'LEED Homes', label: 'LEED Homes' },
    { value: 'LEED Operations and Maintenance', label: 'LEED Operations and Maintenance' },
    { value: 'NGBS Green Home Remodeling Project', label: 'NGBS Green Home Remodeling Project' },
    { value: 'NGBS Green Multifamily Building Certification', label: 'NGBS Green Multifamily Building Certification' },
    { value: 'Other Green Building Certification', label: 'Other Green Building Certification' },
    {
        value: 'Passive House Institute Passive House Standard',
        label: 'Passive House Institute Passive House Standard',
    },
    { value: 'PHIUS +', label: 'PHIUS +' },
]

export const DealDetails: FunctionalComponent<Props> = (props: Props) => {
    const formState = useFormState()

    const [showGreen, setShowShowGreen] = useState(false)
    const [showRehabilitation, setShowRehabilitation] = useState(false)
    const [showStabilization, setShowStabilization] = useState(false)
    const [showOther, setShowOther] = useState(false)
    const toggleGreen = useCallback(() => {
        setShowShowGreen(!showGreen)
    }, [showGreen])

    const toggleRehabilitation = useCallback(() => {
        setShowRehabilitation(!showRehabilitation)
    }, [showRehabilitation])

    const toggleStabilization = useCallback(() => {
        setShowStabilization(!showStabilization)
    }, [showStabilization])

    const toggleOther = useCallback(() => {
        setShowOther(!showOther)
    }, [showOther])

    return (
        <div className={'lender-publish-wrapper'}>
            <div className="lender-publish-page-title mainTitle mainTitle1">
                Now, let’s focus on what matters to you
            </div>
            <div className="lender-publish-page-title mainTitle mainTitle2">Deal Details</div>
            <div className={'row'}>
                <div className={'label'}>Purchase Price *</div>
                <NumberField
                    name={`dealDetails.purchasePrice`}
                    className={'fvalue  indent25'}
                    numberFormat={'currency'}
                    defaultValue={0}
                    cssClassName={'fvalue  indent25'}
                />

                <img src={dollar} className={'dollar'} />
            </div>
            <div className={'row'}>
                <div className={'label'}>Current UPB *</div>
                <NumberField
                    name={`dealDetails.currentUPB`}
                    className={'fvalue  indent25'}
                    numberFormat={'currency'}
                    defaultValue={0}
                    cssClassName={'fvalue  indent25'}
                />
            </div>
            <div className={'row'}>
                <Field name={'dealDetails.cashout'} component={'input'} type="checkbox" className={'GICheckbox'} />
                <div className={'label labelCheckbox'}>Cash Out</div>
            </div>
            <div className={'row'}>
                <div className={'label'}>Construction Cost *</div>
                <NumberField
                    name={`dealDetails.constructionCost`}
                    className={'fvalue  indent25'}
                    numberFormat={'currency'}
                    defaultValue={0}
                    cssClassName={'fvalue  indent25'}
                />

                <img src={dollar} className={'dollar'} />
            </div>
            <div className={'row'}>
                <Field
                    name={'dealDetails.recentConstruction'}
                    component={'input'}
                    type="checkbox"
                    className={'GICheckbox'}
                />
                <div className={'label labelCheckbox constructionCheckBox'}>Current/Recent Construction</div>
            </div>
            <div className={'row'}>
                <div className={'label'}>Escrowed Improvements *</div>
                <NumberField
                    name={`dealDetails.escrowedImprovements`}
                    className={'fvalue  indent25'}
                    numberFormat={'currency'}
                    defaultValue={0}
                    cssClassName={'fvalue  indent25'}
                />

                <img src={dollar} className={'dollar'} />
            </div>
            <div className={'row row2 row3'}>
                <div className={'row2child row3child'}>
                    <div className={'label'}>Prepay/Additional Payoffs *</div>
                    <NumberField
                        name={`dealDetails.prepay`}
                        className={'fvalue  indent25'}
                        numberFormat={'currency'}
                        defaultValue={0}
                        cssClassName={'fvalue  indent25'}
                    />

                    <img src={dollar} className={'dollar dollarAdjust'} />
                </div>
                <div className={'row2child row3child'} style={{ width: '48% !important' }}>
                    <div className={'label'}>Cap Ex *</div>
                    <NumberField
                        name={`dealDetails.budgetedCapEx`}
                        className={'fvalue  indent25'}
                        numberFormat={'currency'}
                        defaultValue={0}
                        cssClassName={'fvalue  indent25'}
                    />

                    <img src={dollar} className={'dollar dollarAdjust'} />
                </div>
            </div>
            <div className={'row'} style={{ marginBottom: '30px' }}></div>
            <div className={'row toggle'} onClick={toggleGreen}>
                <span data-src={'green'} className={'toggoleIcon'}>
                    <i className="material-icons-outlined ">{showGreen ? 'expand_less' : 'expand_more'}</i>
                </span>
                <span className={'toggleLabel'}>Green</span>
            </div>
            {showGreen && (
                <>
                    <div className={'row'}>
                        <div className={'label'}>Green Financing Type</div>
                        <Field
                            component={WhiteReactSelectAdapter}
                            name="dealDetails.greenFinancingType"
                            defaultValue={greenFinancingTypeList[0].value}
                            options={greenFinancingTypeList}
                            isDisabled={false}
                        />
                    </div>
                    <div className={'row'}>
                        <div className={'label'}>Green Building Certification Type</div>
                        <Field
                            component={WhiteReactSelectAdapter}
                            name="dealDetails.greenBuildingCertificationType"
                            defaultValue={greenBuildingCertificationTypeList[0].value}
                            options={greenBuildingCertificationTypeList}
                            isDisabled={false}
                        />
                    </div>
                </>
            )}

            <div className={'row toggle'} onClick={toggleRehabilitation}>
                <span data-src={'rehabilitation'} className={'toggoleIcon'}>
                    <i className="material-icons-outlined ">{showRehabilitation ? 'expand_less' : 'expand_more'}</i>
                </span>
                <span className={'toggleLabel'}>Rehabilitation</span>
            </div>

            {showRehabilitation && (
                <>
                    <div className={'row'}>
                        <Field
                            name={'dealDetails.moderateRehabilitationGateway'}
                            component={'input'}
                            type="checkbox"
                            className={'GICheckbox'}
                        />
                        <div className={'label labelCheckbox'} style={{ width: '250px' }}>
                            Moderate Rehabilitation (Gateway)
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'label'}>Rehabilitation Work ($/Unit) *</div>
                        <NumberField
                            name={`dealDetails.rehabilitationWorkPerUnit`}
                            className={'fvalue  indent25'}
                            numberFormat={'currency'}
                            defaultValue={0}
                            cssClassName={'fvalue  indent25'}
                        />

                        <img src={dollar} className={'dollar'} />
                    </div>
                    <div className={'row'}>
                        <div className={'label'}>Rehabilitation Work Escrow Amount *</div>
                        <NumberField
                            name={`dealDetails.rehabilitationWorkEscrowAmount`}
                            className={'fvalue  indent25'}
                            numberFormat={'currency'}
                            defaultValue={0}
                            cssClassName={'fvalue  indent25'}
                        />

                        <img src={dollar} className={'dollar'} />
                    </div>
                </>
            )}

            <div className={'row toggle'} onClick={toggleStabilization}>
                <span data-src={'stabilization'} className={'toggoleIcon'}>
                    <i className="material-icons-outlined ">{showStabilization ? 'expand_less' : 'expand_more'}</i>
                </span>
                <span className={'toggleLabel'}>Stabilization</span>
            </div>
            {showStabilization && (
                <>
                    <div className={'row'}>
                        <div className={'label'}>Property Stabilization Type</div>
                        <Field
                            component={TextCheckBox}
                            name="dealDetails.propertyStabilizationLevel"
                            options={['Stabilized', 'Nearly Stabilized']}
                        />
                    </div>

                    <div className={'row'}>
                        <div className={'label'}>
                            Property Expected to Reach Stable Residental Physical Occupancy Within (if near-stab)
                        </div>
                        <Field
                            component={TextCheckBox}
                            name="dealDetails.expectedtoReachStable"
                            options={['N/A', '1 - 6 months', '7 - 12 months', '12 - 18 months', 'After 18 months']}
                        />
                    </div>
                </>
            )}

            <div className={'row toggle'} onClick={toggleOther}>
                <span data-src={'other'} className={'toggoleIcon'}>
                    <i className="material-icons-outlined ">{showOther ? 'expand_less' : 'expand_more'}</i>
                </span>
                <span className={'toggleLabel'}>Other</span>
            </div>
            {showOther && (
                <>
                    <div className={'row'}>
                        <div className={'label'}>Condominium Regime Type</div>
                        <Field
                            component={TextCheckBox}
                            name="dealDetails.condominiumRegimeType"
                            options={['Not Applicable', 'Fractured', 'Standard']}
                        />
                    </div>

                    <div className={'row'}>
                        <div className={'label'}>Land Ownership Rights (4662)</div>
                        <Field
                            component={TextCheckBox}
                            name="dealDetails.landOwnershipRights"
                            options={['Fee Simple', 'Leasehold']}
                        />
                    </div>
                </>
            )}

            <div className={'row'} style={{ marginBottom: '150px' }}></div>
            {/*language=scss*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .toggoleIcon {
                        color: #97a2b4;
                        margin-right: 15px;
                    }
                    .toggle {
                        cursor: pointer;
                    }
                    .dollar {
                        position: relative;
                        top: -27px;
                        left: 10px;
                    }
                    :global(.indent25) {
                        text-indent: 25px;
                        text-align: left !important;
                    }
                    .row3 {
                        gap: 10px !important;
                    }
                    .row3child {
                        width: 48% !important;
                    }
                    .dollarAdjust {
                        top: -32px !important;
                    }

                    .constructionCheckBox {
                        width: 200px;
                        position: relative;
                        top: 5px;
                    }
                `}
            </style>
        </div>
    )
}
