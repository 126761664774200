import React, { useCallback, useState } from 'react'

type Props = any
import { FieldRenderProps } from 'react-final-form'

// @ts-ignore
export function TextCheckBox({ input, meta, ...props }: FieldRenderProps<any, any>) {
    const [currentValue, setcurrentValue] = useState(input.value)
    const onChange = useCallback(
        (value) => {
            if (value == currentValue) {
                input.onChange(null)
                setcurrentValue(null)
            } else {
                input.onChange(value)
                setcurrentValue(value)
            }
        },
        [currentValue],
    )

    return (
        <div className={'input-wrapper'} data-testid={input.name}>
            <input type="hidden" name={input.name} />
            {props.options.map((option, id) => {
                return (
                    <React.Fragment key={option + id}>
                        <div
                            className={'input-option' + (currentValue == option ? ' selected' : '')}
                            onClick={() => {
                                onChange(option)
                            }}
                        >
                            {option}
                        </div>
                    </React.Fragment>
                )
            })}
            <style jsx>{`
                .input-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
                .selected {
                    border: 1px solid #4486ff !important;
                    background: #dae7ff;
                    color: #4486ff !important;
                }

                .input-option {
                    cursor: pointer;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 54px;
                    height: 36px;
                    border: 1px solid #97a2b4;
                    border-radius: 4px 0px 0px 4px;
                    flex: none;
                    order: 0;
                    flex-grow: 1;
                    margin: 0px -1px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    color: #97a2b4;
                }
            `}</style>
        </div>
    )
}
