import React, { useCallback, useMemo, FocusEvent } from 'react'

import numeral from 'numeral'
import MaskedInput from 'react-text-mask'
import { Field, FieldProps, useField } from 'react-final-form'

export type NumberFieldFormat =
    | 'currency'
    | 'percent'
    | 'years'
    | 'months'
    | 'decimal'
    | 'unit'
    | 'int'
    | 'number'
    | 'days'
    | 'multiple'
type Props = {
    numberFormat: NumberFieldFormat
    readOnly?: boolean
    defaultValue?: any
    inverseValues?: boolean
    maxNumber?: number
    cssClassName?: string
    containerClassName?: string
    fontWeight?: number
    onFocus?: any
    onBlur?: any
    onChange?: any
    value?: any
    onClick?: any
    alwaysShowTwoDecimals?: boolean
    style?: any
    percentDecimalLimit?: number
    allowNumberNegative?: boolean
}
export const NumberField = React.memo(function NumberField(props: Props & FieldProps<any, any>) {
    const { numberFormat, percentDecimalLimit, allowNumberNegative, ...fldProps } = props
    const value = props.value
    const numberMask = useMemo(() => {
        switch (numberFormat) {
            case 'currency':
                return createNumberMask({
                    prefix: '$',
                    suffix: '',
                    allowNegative: true,
                })
            case 'percent':
                return createNumberMask({
                    prefix: '',
                    suffix: '%',
                    allowDecimal: true,
                    integerLimit: 3,
                    decimalLimit: percentDecimalLimit || 3,
                })
            case 'multiple':
                return createNumberMask({
                    prefix: '',
                    suffix: 'x',
                    allowDecimal: true,
                    integerLimit: 3,
                    decimalLimit: 3,
                })
            case 'years':
                return createNumberMask({
                    prefix: '',
                    suffix: ' years',
                })
            case 'months':
                return createNumberMask({
                    prefix: '',
                    suffix: ' months',
                })
            case 'days':
                return createNumberMask({
                    prefix: '',
                    suffix: ' days',
                })
            case 'unit':
                return createNumberMask({
                    prefix: '$',
                    suffix: '/unit',
                })
            case 'decimal':
                return createNumberMask({
                    prefix: '',
                    suffix: '',
                    allowDecimal: true,
                    decimalLimit: 2,
                })
            case 'int':
                return createNumberMask({
                    prefix: '',
                    suffix: '',
                    allowDecimal: false,
                })
            case 'number':
                return createNumberMask({
                    prefix: '',
                    suffix: '',
                    includeThousandsSeparator: false,
                    allowNumberNegative: allowNumberNegative || false,
                })
        }
    }, [numberFormat])
    const parser = useMemo(() => {
        switch (numberFormat) {
            case 'percent':
                return {
                    parse: (v) => {
                        //  console.log(fldProps.name + ' parse', v)
                        return v?.toString().match(/\d+\.0?%$/) ? v : numeral(v).value()
                    },
                    format: (v) => {
                        // console.log(fldProps.name + ' format', v, v?.toString().match(/\d+\.$/))
                        if (v?.toString().match(/\d+\.0?%$/)) {
                            return v
                        }
                        let format = '0.[00]%'
                        if (percentDecimalLimit) {
                            format = '0.['
                            for (let i = 1; i <= percentDecimalLimit; i++) {
                                format += 0
                            }
                            format += ']%'
                        }
                        return v == null ? '' : numeral(v).format(props.alwaysShowTwoDecimals ? '0.00%' : format)
                    },
                }
            case 'multiple':
                return {
                    parse: (v) => {
                        //   console.log(fldProps.name + ' parse', v)
                        return v?.toString().match(/\d+\.xy$/) ? v : numeral(v).value()
                    },
                    format: (v) => {
                        // console.log(fldProps.name + ' format', v, v?.toString().match(/\d+\.x/))
                        if (v?.toString().match(/\d+\.x$/)) {
                            return v
                        }
                        const ret =
                            v == null ? '' : numeral(v).format(props.alwaysShowTwoDecimals ? '0.00' : '0.[00]') + 'x'
                        return ret
                    },
                }
            case 'currency':
                return {
                    parse: (v) => numeral(v).value(),
                    format: (v) => {
                        return v == null ? '' : numeral(v).format('($0,0)')
                    },
                }
            default:
                return {
                    parse: (v) => numeral(v).value(),
                    format: (v) => {
                        return v == null ? '' : v.toString()
                    },
                }
        }
    }, [numberFormat])
    const fld = useField(props.name)
    const onFocus = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            e.persist()
            const el = e.target
            if (el.setSelectionRange) {
                setTimeout(function () {
                    el.setSelectionRange(el.value.length || 0, el.value.length || 0)
                }, 2)
            }
            if (fld?.input?.onFocus) fld?.input?.onFocus(e)
            if (props.onFocus) props.onFocus(el)
        },
        [fld, props.onFocus],
    )
    const onBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            const el = e.target
            if (props.input?.onBlur) {
                props.input.onBlur(el)
            } else {
                if (props.onBlur) props.onBlur(el)
            }
        },
        [fld, props.onBlur, props.input?.onBlur],
    )
    const onChange = (e, inputProps) => {
        if (props?.maxNumber && e.target.value > props?.maxNumber) {
            e.target.value = props.maxNumber
        }
        if (inputProps?.input?.onChange) inputProps?.input?.onChange(e)
        if (inputProps?.onChange) inputProps.onChange(e)
    }
    const fontWeight = props?.fontWeight && props?.fontWeight > 0 ? props?.fontWeight : 500
    return (
        <Field {...fldProps} {...parser}>
            {(props) => (
                <>
                    <span className={`input-container ${props.readOnly ? 'readOnly' : ''} ${
                            props.containerClassName ? props.containerClassName : ''
                        }`} style={props.style || {}}>
                        {props.readOnly && props?.inverseValues && (
                            <span
                                className={'input ' + (props.cssClassName ? props.cssClassName : '')}
                                onClick={() => {
                                    if (props.onClick) props.onClick()
                                }}
                            >
                                {wrapIntoBrackets(props?.input?.value)}
                            </span>
                        )}

                        {props.readOnly && !props?.inverseValues ? (
                            <span
                                className={'input ' + props.cssClassName}
                                onClick={() => {
                                    if (props.onClick) props.onClick()
                                }}
                            >
                                {props.input.value}
                            </span>
                        ) : (
                            <MaskedInput
                                name={props.input.name}
                                value={value || props.input.value}
                                onChange={(e) => onChange(e, props)}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                onClick={() => {
                                    if (props.onClick) props.onClick()
                                }}
                                mask={numberMask}
                                className={'input ' + props.cssClassName}
                                readOnly={props.readOnly}
                            />
                        )}
                    </span>
                    {/*language=SCSS*/}
                    <style jsx>
                        {`
                            @import './src/scss/colors.scss';
                            .input-container {
                                position: relative;
                                width: 100%;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 1px;
                                    bottom: 0;
                                    left: 0;
                                    border-top: 1px solid $light-gray;
                                    opacity: 1;
                                }

                                &:focus-within:after {
                                    border-top: 1px solid transparent;
                                }

                                :global(.input) {
                                    width: 100%;
                                    border-width: 0;
                                    text-align: right;
                                    font-weight: ${fontWeight};
                                    font-size: 14px;
                                    color: $default-text-color;
                                    height: 26px;
                                    border-top: 1px solid transparent;
                                    background-color: transparent;
                                    &:focus {
                                        border: 1px solid $blue;
                                        border-radius: 3px;
                                        outline: none;
                                    }
                                }
                                &.readOnly :global(.input:focus) {
                                    border: inherit;
                                }
                            }
                            .input-container.readOnly:after {
                                border-top: 1px solid transparent;
                            }
                        `}
                    </style>
                </>
            )}
        </Field>
    )
})
const dollarSign = '$'
const emptyString = ''
const comma = ','
const period = '.'
const minus = '-'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const minusRegExp = /-/
const nonDigitsRegExp = /\D+/g
const number = 'number'
const digitRegExp = /\d/
const caretTrap = '[]'

export default function createNumberMask({
    prefix = dollarSign as any,
    suffix = emptyString as any,
    includeThousandsSeparator = true,
    thousandsSeparatorSymbol = comma,
    allowDecimal = false,
    decimalSymbol = period,
    decimalLimit = 2,
    requireDecimal = false,
    allowNegative = false,
    allowLeadingZeroes = false,
    integerLimit = null,
    allowNumberNegative = false
} = {}) {
    const prefixLength = (prefix && prefix.length) || 0
    const suffixLength = (suffix && suffix.length) || 0
    const thousandsSeparatorSymbolLength = (thousandsSeparatorSymbol && thousandsSeparatorSymbol.length) || 0

    function numberMask(rawValue = emptyString) {
        //     console.log('numberMask', rawValue)
        const rawValueLength = rawValue.length
        if (rawValue === emptyString || (rawValue[0] === prefix[0] && rawValueLength === 1)) {
            return prefix.split(emptyString).concat([digitRegExp]).concat(suffix.split(emptyString))
        } else if (rawValue === decimalSymbol && allowDecimal) {
            return prefix.split(emptyString).concat(['0', decimalSymbol, digitRegExp]).concat(suffix.split(emptyString))
        }

        const isNegative = (rawValue[0] === minus || rawValue[0] === '(') && allowNegative
        //If negative remove "-" sign
        if (isNegative) {
            rawValue = rawValue.toString().substr(1)
        }

        if (allowNumberNegative) {
            rawValue = rawValue.toString()
        }

        const indexOfLastDecimal = rawValue.lastIndexOf(decimalSymbol)
        const hasDecimal = indexOfLastDecimal !== -1

        let integer
        let fraction
        let mask

        // remove the suffix
        if (rawValue.slice(suffixLength * -1) === suffix) {
            rawValue = rawValue.slice(0, suffixLength * -1)
        }

        if (hasDecimal && (allowDecimal || requireDecimal)) {
            integer = rawValue.slice(rawValue.slice(0, prefixLength) === prefix ? prefixLength : 0, indexOfLastDecimal)

            fraction = rawValue.slice(indexOfLastDecimal + 1, rawValueLength)
            fraction = convertToMask(fraction.replace(nonDigitsRegExp, emptyString))
        } else {
            if (rawValue.slice(0, prefixLength) === prefix) {
                integer = rawValue.slice(prefixLength)
            } else {
                integer = rawValue
            }
        }

        if (integerLimit && typeof integerLimit === number) {
            const thousandsSeparatorRegex = thousandsSeparatorSymbol === '.' ? '[.]' : `${thousandsSeparatorSymbol}`
            const numberOfThousandSeparators = (integer.match(new RegExp(thousandsSeparatorRegex, 'g')) || []).length

            integer = integer.slice(0, integerLimit + numberOfThousandSeparators * thousandsSeparatorSymbolLength)
        }

        if (!allowNumberNegative) {
            integer = integer.replace(nonDigitsRegExp, emptyString)
        } else {
            integer = integer.replace(/[^\d-]/ig, emptyString)
        }
        
        if (!allowLeadingZeroes) {
            integer = integer.replace(/^0+(0$|[^0])/, '$1')
        }

        integer = includeThousandsSeparator ? addThousandsSeparator(integer, thousandsSeparatorSymbol) : integer

        mask = convertToMask(integer)

        if ((hasDecimal && allowDecimal) || requireDecimal === true) {
            if (rawValue[indexOfLastDecimal - 1] !== decimalSymbol) {
                mask.push(caretTrap)
            }

            mask.push(decimalSymbol, caretTrap)

            if (fraction) {
                if (typeof decimalLimit === number) {
                    fraction = fraction.slice(0, decimalLimit)
                }

                mask = mask.concat(fraction)
            }

            if (requireDecimal === true && rawValue[indexOfLastDecimal - 1] === decimalSymbol) {
                mask.push(digitRegExp)
            }
        }

        if (prefixLength > 0) {
            mask = prefix.split(emptyString).concat(mask)
        }

        if (isNegative) {
            // If user is entering a negative number, add a mask placeholder spot to attract the caret to it.
            if (mask.length === prefixLength) {
                mask.push(digitRegExp)
            }

            mask = ['('].concat(mask).concat(')')
        }

        if (suffix.length > 0) {
            mask = mask.concat(suffix.split(emptyString))
        }

        return mask
    }

    numberMask.instanceOf = 'createNumberMask'

    return numberMask
}

function convertToMask(strNumber) {
    return strNumber.split(emptyString).map((char) => (digitRegExp.test(char) ? digitRegExp : char))
}

// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n, thousandsSeparatorSymbol) {
    return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol)
}

const wrapIntoBrackets = (value: string) => {
    if (!value) {
        return ''
    }

    if (value.startsWith('(') && value.endsWith(')')) {
        return value
    }

    return `(${value})`
}
