import React, { CSSProperties, PropsWithChildren } from 'react'

type Props = {
    className?: string
    onClick: () => void
    disabled?: boolean
    style?: CSSProperties
}

export function ButtonBlueEdge(props: PropsWithChildren<Props>) {
    return (
        <>
            <button
                className={`edit-report ${props.className} ${props.disabled && 'disabled'}`}
                onClick={props.onClick}
                disabled={props.disabled}
                style={props.style}
            >
                {props.children}
            </button>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .edit-report {
                        color: $blue;
                        height: 36px;
                        padding: 0 18.5px;
                        border-radius: 3px;
                        border: 1px solid $blue;
                        font-weight: 500;
                        font-size: 13px;
                        outline: none;
                        :global(img) {
                            position: relative;
                            top: -3px;
                            margin-left: 5px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .disabled {
                        color: $blue-gray;
                        border: 1px solid $blue-gray;
                        opacity: 0.5;
                    }
                `}
            </style>
        </>
    )
}
